<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
<script>
  export default {
    name: "StartOverview",
  }
</script>

<template>
  <div>
    <h2>{{t('startOverview.label')}}</h2>
  </div>
</template>

<style scoped lang="scss">
 
</style>

<i18n>
  {}
</i18n>
